import { collection, query, where, limit } from 'firebase/firestore';
import { useFirestore, useFirestoreCollection } from 'reactfire';

export function useBillingCheck(sku, supportId) {
    // HOOKS
    const firestore = useFirestore();

    // FIRESTORE
    const commerceCollection = collection(firestore, `Products/${sku}/Commerce/`);
    const activeQuery = query(commerceCollection, where('appAccountToken', '==', supportId.toLowerCase()), limit(1));
    const { status, data: events } = useFirestoreCollection(activeQuery);

    if (status === 'loading') {
        return false;
    }

    if (events.docs.length > 0) {
        return true;
    }

    return false;
}
