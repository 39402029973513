import { ChevronRightIcon } from '@heroicons/react/24/solid'
import { useFirestore, useFirestoreCollection } from 'reactfire';
import { collection, query, where, orderBy } from 'firebase/firestore';
import { formatRelativeDate, titleCase } from './Helpers';
import { EmptyState } from "./Helpers";

export default function SubscriptionsList({ sku, handler, environment = 'Production' }) {
    // FIRESTORE
    const firestore = useFirestore();
    const commerceCollection = collection(firestore, `Products/${sku}/Commerce`);
    const activeQuery = query(commerceCollection, where('environment', '==', environment), where('lastNotificationType', '!=', 'ONE_TIME_CHARGE'), orderBy('lastNotificationType', 'desc'), orderBy('updated', 'desc'));
    const { status, data: commerceItems } = useFirestoreCollection(activeQuery);

    if (status === 'loading') {
        return 'Loading...';
    }

    const selectItem = (e, item) => {
        e.preventDefault();
        handler(item);
    }

    return (
        <div className="bg-white shadow overflow-hidden sm:rounded-md">

            {commerceItems.docs.length === 0 && <EmptyState message={`No subscriptions found${environment !== undefined && ' (' + environment + ')'}.`} border={false} />}

            {commerceItems.docs.length > 0 && <ul className="divide-y divide-gray-200">
                {commerceItems.docs.map((item, itemIndex) => (
                    <li key={itemIndex}>
                        <a href={item.id} onClick={(e) => selectItem(e, { ...item.data(), id: item.id })} className="block hover:bg-gray-50">
                            <div className="flex items-center px-4 py-4 sm:px-6">
                                <div className="min-w-0 flex-1 flex items-center">
                                    <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                        <div>
                                            <p className="text-sm font-medium text-black-600 truncate">
                                                {/*formatBundleId(item.data().bundleId)*/}
                                            </p>
                                            <p className="mt-2 flex items-center text-sm text-gray-500">
                                                <span className="truncate">
                                                    {item.data().environment === "Sandbox" && <span className="mr-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">Sandbox</span>}
                                                    {item.data().environment === "Production" && <span className="mr-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-pink-100 text-pink-800">Production</span>}

                                                    {item.data().lastNotificationType && titleCase(item.data().lastNotificationType)} &nbsp;
                                                    {!item.data().lastNotificationType && item.data().type && titleCase(item.data().type)}

                                                    {(item.data().lastSubtype) && <span>({item.data().lastSubtype.toLowerCase()})</span>}
                                                </span>
                                            </p>
                                        </div>
                                        <div className="hidden md:block">
                                            <div>
                                                <p className="text-sm text-gray-900">
                                                    Last updated {formatRelativeDate(item.data().updated.toDate())}
                                                </p>
                                                <p className="mt-2 flex items-center text-sm text-gray-500">
                                                    <img className="flex-shrink-0 mr-1.5 h-5 w-5" src={`/images/ios.png`} alt="" />
                                                    {item.data().productId}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div>
                            </div>
                        </a>
                    </li>
                ))}
            </ul>}
        </div>
    )
}