import React from 'react';

export default function Flag({ iso }) {
    const getFlagImage = (iso) => {
        const savedImages = ['ar', 'at', 'au', 'br', 'ca', 'ch', 'cn', 'de', 'es', 'fr', 'gb', 'jp', 'kr', 'pt', 'us', 'za', 'mx'];
        if (savedImages.includes(iso)) {
            return `/flags/${iso}.svg`;
        } else {
            return '/flags/un.svg';
        }
    };

    const flagImage = getFlagImage(iso);

    return <img src={flagImage} className="w-6 h-auto" alt={`Flag for ${iso}`} />;
};