import { collection, query, orderBy } from 'firebase/firestore';
import { useFirestore, useFirestoreCollection } from 'reactfire';

export default function useCompetitors(sku) {
    const firestore = useFirestore();
    const competitorsCollection = collection(firestore, `Products/${sku}/Competitors`);
    var activeQuery = query(competitorsCollection, orderBy('title', 'asc'));

    const { status, data: competitors } = useFirestoreCollection(activeQuery);

    if (status === 'loading') {
        return undefined;
    }

    return competitors;
}
