import Header from "./Header"

export default function ContactPage() {
    return (
        <>
            <Header />

            <div className="relative overflow-hidden bg-white py-16">
                <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 relative px-4 sm:px-6 lg:px-8">
                    <div className="mx-auto max-w-prose text-lg">
                        <h1>
                            <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                                Contact
                            </span>
                        </h1>
                        <p className="mt-8 text-xl leading-8 text-gray-700">
                            Want to reach out with questions or comments? use the form below.
                        </p>
                    </div>

                    <div className="prose prose-lg prose-blue mx-auto mt-6 text-gray-700">
                        <form>

                            <div className="space-y-6 sm:space-y-5">

                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Name
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            autoComplete="given-name"
                                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-[#3600C0] focus:ring-[#3600C0] sm:max-w-xs sm:text-sm"
                                        />
                                    </div>
                                </div>

                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Contact email address
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <input
                                            type="text"
                                            name="email"
                                            id="email"
                                            autoComplete="email"
                                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-[#3600C0] focus:ring-[#3600C0] sm:max-w-xs sm:text-sm"
                                        />
                                    </div>
                                </div>

                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                    <label htmlFor="comments" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                        Questions, comments
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <textarea
                                            id="about"
                                            name="about"
                                            rows={3}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#3600C0] focus:ring-[#3600C0] sm:text-sm"
                                            placeholder={''}
                                            defaultValue={''}
                                        />
                                    </div>
                                </div>

                                <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                                    <button type="submit" className="inline-flex justify-center rounded-md border border-transparent bg-[#3600C0] py-2 px-4 text-sm font-medium text-white shadow-sm hover:[#3600C0] focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}