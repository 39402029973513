import { formatRelativeDate, titleCase } from './Helpers';
import CommerceItemList from './CommerceItemList';

export default function CommerceItem({ sku, item, handler }) {
    const goBack = () => {
        handler(undefined);
    }

    const formatPrice = (iso, n) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: iso }).format(n/1000);
    }

    return (
        <>
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                        <div className="ml-4 mt-2">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Commerce item</h3>
                            {item.purchaseDate && <p className="mt-1 max-w-2xl text-sm text-gray-500">Purchased {formatRelativeDate(new Date(item.purchaseDate))} (TZ?)</p>}
                            {item.updated && <p className="mt-1 max-w-2xl text-sm text-gray-500">Updated {formatRelativeDate(item.updated.toDate())}</p>}
                        </div>
                        <div className="ml-4 mt-2 flex-shrink-0">
                            <button type="button" onClick={() => { goBack() }} className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Back</button>
                        </div>
                    </div>
                </div>

                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">SKU</dt>
                            <dd className="mt-1 text-sm text-gray-900">{item.bundleId}</dd>
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Product ID</dt>
                            <dd className="mt-1 text-sm text-gray-900">{item.productId}</dd>
                        </div>

                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Storefront</dt>
                            <dd className="mt-1 text-sm text-gray-900">
                                {(item.storefront) && item.storefront}
                                {!(item.storefront) && "—"}
                            </dd>
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Environment</dt>
                            <dd className="mt-1 text-sm text-gray-900">
                                {(item.environment) && item.environment}
                                {!(item.environment) && "—"}
                            </dd>
                        </div>

                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Currency</dt>
                            <dd className="mt-1 text-sm text-gray-900">
                                {(item.currency) && item.currency}
                                {!(item.currency) && "—"}
                            </dd>
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Price</dt>
                            <dd className="mt-1 text-sm text-gray-900">
                                {(item.currency && item.price) && formatPrice(item.currency, item.price)}
                                {(item.quantity) && ` x ${item.quantity}`}
                                {!(item.currency && item.price) && "—"}
                            </dd>
                        </div>

                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Kind</dt>
                            <dd className="mt-1 text-sm text-gray-900">
                                {(item.kind) && item.kind}
                                {!(item.kind) && "—"}
                            </dd>
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Transaction reason</dt>
                            <dd className="mt-1 text-sm text-gray-900">
                                {(item.transactionReason !== undefined) && item.transactionReason}
                                {(item.transactionReason === undefined) && "—"}
                            </dd>
                        </div>

                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Last notification type</dt>
                            <dd className="mt-1 text-sm text-gray-900">
                                {(item.lastNotificationType) && titleCase(item.lastNotificationType)}
                                {!(item.lastNotificationType) && "—"}
                            </dd>
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Last subtype</dt>
                            <dd className="mt-1 text-sm text-gray-900">
                                {(item.lastSubtype !== undefined) && titleCase(item.lastSubtype)}
                                {(item.lastSubtype === undefined) && "—"}
                            </dd>
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Support ID</dt>
                            <dd className="mt-1 text-sm text-gray-900">
                                {(item.appAccountToken !== undefined) && item.appAccountToken}
                                {(item.appAccountToken === undefined) && "—"}
                            </dd>
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Environment</dt>
                            <dd className="mt-1 text-sm text-gray-900">{item.environment}</dd>
                        </div>
                    </dl>
                </div>

                {item.id !== undefined && <CommerceItemList sku={sku} itemId={item.id} />}
            </div>
        </>
    )
}

/*
purchaseDate = 12343543534
*/