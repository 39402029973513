import React, { useState } from 'react';
import SubscriptionsList from "./SubscriptionsList";
import IapsList from "./IapsList";
import CommerceItem from "./CommerceItem";
import { CurrencyDollarIcon } from '@heroicons/react/24/solid';
import EnvironmentToggle from './EnvironmentToggle';

const tabs = [
    { name: 'Subscriptions', icon: CurrencyDollarIcon },
    { name: 'Purchases', icon: CurrencyDollarIcon },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function CommerceMain({ sku }) {
    // STATE
    const [selectedItem, setSelectedItem] = useState(undefined);
    const [currentTab, setCurrentTab] = useState('Subscriptions');
    const [currentEnvironment, setCurrentEnvironment] = useState('Production');

    // LIFTING STATE
    const changeSelectedItem = (item) => {
        setSelectedItem(item);
    }

    // TABS

    function selectTab(e, tabName) {
        e.preventDefault();

        setCurrentTab(tabName);
        setSelectedItem(undefined);
    }

    function dropdownChange(e) {
        setCurrentTab(e.target.value);
        setSelectedItem(undefined);
    }

    const environmentToggleChanged = (environment) => {
        setCurrentEnvironment(environment);
    }

    return (
        <div className="w-full">

            {/* Tab control */}
            <div className="mb-2">
                <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">Select a tab</label>
                    <select id="tabs" name="tabs" onChange={dropdownChange}
                        className="block mt-4 mb-2 w-full focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                        defaultValue={tabs.find((tab) => tab.name).name}>
                        {tabs.map((tab) => (
                            <option key={tab.name}>{tab.name}</option>
                        ))}
                    </select>
                </div>
                <div className="hidden sm:block">
                    <div className="border-b border-gray-200">
                        <nav className="-mb-px flex justify-between" aria-label="Tabs">
                            <div className="flex space-x-8">
                                {tabs.map((tab) => (
                                    <a key={tab.name} href="#0" onClick={(e) => selectTab(e, tab.name)}
                                        className={classNames(
                                            tab.name === currentTab
                                                ? 'border-blue-500 text-blue-600'
                                                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                            'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm'
                                        )}>
                                        <tab.icon className={classNames(tab.name === currentTab ? 'text-blue-500' : 'text-gray-400 group-hover:text-gray-500', '-ml-0.5 mr-2 h-5 w-5')} aria-hidden="true" />
                                        <span>{tab.name}</span>
                                    </a>
                                ))}
                            </div>

                            <div className="py-2">
                                <EnvironmentToggle handler={environmentToggleChanged} />
                            </div>
                        </nav>
                    </div>
                </div>
            </div>

            {(currentTab === "Subscriptions" && selectedItem === undefined) && <SubscriptionsList handler={changeSelectedItem} sku={sku} environment={currentEnvironment} />}
            {(currentTab === "Subscriptions" && selectedItem !== undefined) && <CommerceItem sku={sku} item={selectedItem} handler={changeSelectedItem} />}

            {(currentTab === "Purchases" && selectedItem === undefined) && <IapsList handler={changeSelectedItem} sku={sku} environment={currentEnvironment} />}
            {(currentTab === "Purchases" && selectedItem !== undefined) && <CommerceItem sku={sku} item={selectedItem} handler={changeSelectedItem} />}
        </div>
    )
}