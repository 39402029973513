import React from 'react';
import { useUser, useSigninCheck } from 'reactfire';
import PortalHomePage from './PortalHome.js';
import SignInPage from './SignInPage.js';

export default function AuthCheck() {
    const { status, data: signInCheckResult } = useSigninCheck();
    const { data: authUser } = useUser();

    if (status === 'loading') {
        return "LOADING (AuthCheck)...";
    }

    if (signInCheckResult.signedIn === true && authUser != null && authUser.uid != null) {
        console.log("[AuthCheck] will render ApplicationHome");
        return <PortalHomePage authUser={authUser} />;
    } else {
        console.log("[AuthCheck] will render SignInPage");
        return <SignInPage />;
    }
}