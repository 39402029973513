import React, { useState } from 'react';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function EnvironmentToggle({ handler }) {
    const [environment, setEnvironment] = useState('Production');

    const handleClick = (e, env) => {
        e.preventDefault();
        setEnvironment(env);
        handler(env);
    }

    return (
        <div className="flex space-x-1 rounded-lg bg-slate-100 p-0.5" role="tablist" aria-orientation="horizontal">
            <button className={classNames("flex items-center rounded-md py-[0.4375rem] pl-2 pr-2 text-sm font-semibold lg:pr-3",
                    environment === 'Production' ? "bg-white shadow" : "")}
                id="toggle-production" role="tab" type="button" tabindex="0" onClick={(e) => handleClick(e, "Production")}>
                <svg className="h-5 w-5 flex-none stroke-sky-500" fill="none" strokeWidth="1.5" strokeLinecap="round"
                    strokeLinejoin="round" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.25 10c0 1-1.75 6.25-7.25 6.25S2.75 11 2.75 10 4.5 3.75 10 3.75 17.25 9 17.25 10Z"></path>
                    <circle cx="10" cy="10" r="2.25"></circle>
                </svg>
                <span className="sr-only lg:not-sr-only lg:ml-2 text-slate-900">Production</span>
            </button>
            <button className={classNames("flex items-center rounded-md py-[0.4375rem] pl-2 pr-2 text-sm font-semibold lg:pr-3",
                    environment === 'Sandbox' ? "bg-white shadow" : "")}
                id="toggle-sandbox" role="tab" type="button" tabindex="-1" onClick={(e) => handleClick(e, "Sandbox")}>
                <svg className="h-5 w-5 flex-none stroke-slate-600" fill="none" strokeWidth="1.5" strokeLinecap="round"
                    strokeLinejoin="round" xmlns="http://www.w3.org/2000/svg">
                    <path d="m13.75 6.75 3.5 3.25-3.5 3.25M6.25 13.25 2.75 10l3.5-3.25"></path>
                </svg>
                <span className="sr-only lg:not-sr-only lg:ml-2 text-slate-600">Sandbox</span>
            </button>
        </div>
    );
}
