import React, { useState, useEffect } from 'react';
import { Fragment } from 'react'
import { getAuth } from 'firebase/auth';
import { useFirebaseApp } from 'reactfire';
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, XCircleIcon } from '@heroicons/react/24/outline'
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import SkuChooser from "./SkuChooser"
import SkuMenu from "./SkuMenu"
import SkuDropdown from './SkuDropdown';

// https://blog.webdevsimplified.com/2022-07/react-router/

const user = {
    name: 'Mike',
    email: 'mike@verbia.com',
    imageUrl: '/images/avatar.jpg',
}

const navigation = [
    { id: 0, title: 'Dashboard', href: '/app' },
    { id: 0, title: 'Transmission', href: '/app/sku/com.verbia.transmission' },
    { id: 0, title: 'PDF Library', href: '/app/sku/com.verbia.pdflibrary' },
    { id: 0, title: 'ePub Pro', href: '/app/sku/com.verbia.mightyreader' },
    { id: 0, title: 'Mighty Market', href: '/app/sku/com.verbia.mightymarket' },
]

const userNavigation = [
    { id: 0, title: 'Settings', href: '#' },
    { id: 1, title: 'Sign out', href: '#' }, // the title "Sign out" is a magic string used below
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function PortalHomePage() {
    // HOOKS
    const app = useFirebaseApp(); // a parent component contains a `FirebaseAppProvider`
    const routerLocation = useLocation();
    const navigate = useNavigate();
    const auth = getAuth(app);

    // set page heading and active navigation button
    useEffect(() => {
        navigation.forEach(element => {
            if (routerLocation.pathname.startsWith(element.href)) {
                setCurrentNavigationItem(element);
            }
        });
    }, [routerLocation]);

    // STATE
    const [currentNavigationItem, setCurrentNavigationItem] = useState(navigation[0]);

    // HELPERS

    // includes fix from https://github.com/FirebaseExtended/reactfire/discussions/228
    const signOut = (e) => {
        auth.signOut().then(() => {
            navigate('/app');
            window.location.reload();
        });
    }

    return (
        <>
            {/* This example requires updating your template:
                <html class="h-full bg-gray-100">
                <body class="h-full"> 
            */}
            <div className="min-h-full">
                <div className="bg-gray-800 pb-32">
                    <Disclosure as="nav" className="bg-gray-800">
                        {({ open }) => (
                            <>
                                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                                    <div className="border-b border-gray-700">
                                        <div className="flex items-center justify-between h-16 px-4 sm:px-0">
                                            <div className="flex items-center">
                                                <div className="flex-shrink-0">
                                                    <img className="h-8" src="/images/vslogo.png" alt="Workflow" />
                                                </div>
                                                <div className="hidden md:block">
                                                    <div className="ml-10 flex items-baseline space-x-4">
                                                        {/* - Navigation menu - */}
                                                        {navigation.map((item) => (
                                                            <button key={item.title} onClick={() => navigate(item.href)} className={classNames((item.id === currentNavigationItem.id) ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'px-3 py-2 rounded-md text-sm font-medium')} aria-current={item ? 'page' : undefined} >
                                                                {item.title}
                                                            </button>
                                                        ))}

                                                        <SkuDropdown />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="hidden md:block">
                                                <div className="ml-4 flex items-center md:ml-6">
                                                    {/* Profile dropdown */}
                                                    <Menu as="div" className="ml-3 relative">
                                                        <div>
                                                            <Menu.Button className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                                                <span className="sr-only">Open user menu</span>
                                                                <img className="h-8 w-8 rounded-full" src={user.imageUrl} alt="" />
                                                            </Menu.Button>
                                                        </div>
                                                        <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                                                            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                {userNavigation.map((item) => (
                                                                    <Menu.Item key={item.title}>
                                                                        {({ active }) => (
                                                                            <>
                                                                                {item.title === "Sign out" && <button onClick={() => signOut()} className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>
                                                                                    {item.title}
                                                                                </button>}
                                                                                {item.title !== "Sign out" && <button onClick={() => navigate(item.href)} className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>
                                                                                    {item.title}
                                                                                </button>}
                                                                            </>
                                                                        )}
                                                                    </Menu.Item>
                                                                ))}
                                                            </Menu.Items>
                                                        </Transition>
                                                    </Menu>
                                                </div>
                                            </div>
                                            <div className="-mr-2 flex md:hidden">
                                                {/* Mobile menu button */}
                                                <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                                    <span className="sr-only">Open main menu</span>
                                                    {open ? (
                                                        <XCircleIcon className="block h-6 w-6" aria-hidden="true" />
                                                    ) : (
                                                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                                    )}
                                                </Disclosure.Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Disclosure.Panel className="border-b border-gray-700 md:hidden">
                                    <div className="px-2 py-3 space-y-1 sm:px-3">
                                        {/* - Navigation bar - */}
                                        {navigation.map((item) => (
                                            <Disclosure.Button key={item.title} as="button" onClick={() => navigate(item.href)} className={classNames((item.id === currentNavigationItem.id) ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'block px-3 py-2 rounded-md text-base font-medium')} aria-current={item ? 'page' : undefined}>
                                                {item.title}
                                            </Disclosure.Button>
                                        ))}
                                    </div>
                                    <div className="pt-4 pb-3 border-t border-gray-700">
                                        <div className="flex items-center px-5">
                                            <div className="flex-shrink-0">
                                                <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" />
                                            </div>
                                            <div className="ml-3">
                                                <div className="text-base font-medium leading-none text-white">{user.name}</div>
                                                <div className="text-sm font-medium leading-none text-gray-400">{user.email}</div>
                                            </div>
                                        </div>
                                        <div className="mt-3 px-2 space-y-1">
                                            {userNavigation.map((item) => (
                                                <Disclosure.Button key={item.title} as="button" onClick={() => navigate(item.href)} className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700">
                                                    {item.title}
                                                </Disclosure.Button>
                                            ))}
                                        </div>
                                    </div>
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                </div>

                <main className="-mt-32">
                    <div className="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
                        {/* Replace with your content */}
                        <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
                            <Routes> {/* relative to /app/ */}
                                <Route path="/" element={<SkuChooser />} />
                                <Route path="sku/:skuId/*" element={<SkuMenu />} />
                                {/* <Route path="*" element={<NotFoundPage />} /> */}
                            </Routes>
                        </div>
                        {/* /End replace */}
                    </div>
                </main>
            </div>
        </>
    )
}