import { collection, query, where, orderBy, limit, startAfter, startAt } from 'firebase/firestore';
import { useFirestore, useFirestoreCollection } from 'reactfire';

export default function usePaginatedReviews(startItem, include, rpp, verbiaSku, competitorSku) {
    const firestore = useFirestore();
    const reviewsCollection = collection(firestore, `Products/${verbiaSku}/Competitors/${competitorSku}/Reviews`);
    var activeQuery = undefined;

    if (startItem === null) {
        activeQuery = query(reviewsCollection, where('vsHidden', '==', false), orderBy('vsHidden', 'desc'), orderBy('updated', 'desc'), limit(rpp));
    } else {
        activeQuery = (include) ? query(reviewsCollection, where('vsHidden', '==', false), orderBy('vsHidden', 'desc'), orderBy('updated', 'desc'), limit(rpp), startAt(startItem)) : 
                                  query(reviewsCollection, where('vsHidden', '==', false), orderBy('vsHidden', 'desc'), orderBy('updated', 'desc'), limit(rpp), startAfter(startItem));
    }

    const { status, data: reviews } = useFirestoreCollection(activeQuery);

    if (status === 'loading') {
        return undefined;
    }

    return reviews;
}
