import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import LandingPage from './Landing/Index';
import PortalPage from './Portal/Index';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LoadingProgress from "./LoadingProgress.js";
import { FirebaseAppProvider } from 'reactfire';
import './index.css';

import LandingMain from './Landing/LandingMain';
import PrivacyPage from './Landing/Privacy';
import TermsPage from './Landing/Terms';
import ContactPage from './Landing/Contact';

const fbconfig = {
    apiKey: "AIzaSyAQdpXnNY-H4nbnOtVMcSenGIJdeWbn3ss",
    authDomain: "verbia-web.firebaseapp.com",
    projectId: "verbia-web",
    storageBucket: "verbia-web.appspot.com",
    messagingSenderId: "236248550305",
    appId: "1:236248550305:web:b15bf8e174263997a0d9e0",
    measurementId: "G-QCC2495GN1"
};

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback={<LoadingProgress />}>
            <FirebaseAppProvider firebaseConfig={fbconfig} suspense={true}>
                <BrowserRouter>
                    <Routes>
                        <Route path="*" element={<LandingPage />}>
                            <Route path="*" element={<LandingMain />} />
                            <Route path="privacy/*" element={<PrivacyPage />} />
                            <Route path="terms/*" element={<TermsPage />} />
                            <Route path="contact/*" element={<ContactPage />} />
                        </Route>
                        <Route path="/app/*" element={<PortalPage />} />
                    </Routes>
                </BrowserRouter>
            </FirebaseAppProvider>
        </Suspense>
    </React.StrictMode>,
    document.getElementById('root')
);