import React, { useState, useEffect } from 'react';
import { collection, limit, orderBy, query, getDocs } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import { strToDate, relativeDateString, formatBytes, NewlinesToBr } from './FormatHelpers';

export default function CompetitorListing({ verbiaSku, theirSku = 'com.accuradio.AccuRadio' }) {
    // STATE
    const [loading, setLoading] = useState(true);
    const [competitorData, setCompetitorData] = useState(null);

    // HOOKS
    const firestore = useFirestore();
    const collectionRef = collection(firestore, `Products/${verbiaSku}/Competitors/${theirSku}/Info`);
    const q = query(collectionRef, orderBy('vsRetrieved', 'desc'), limit(1));

    useEffect(() => {
        const fetchData = async () => {
            try {
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    const data = querySnapshot.docs[0].data();
                    setCompetitorData(data);
                } else {
                    console.log("No data found");
                    setCompetitorData(null); // Reset competitorData when no data is found
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [verbiaSku, theirSku, q]);


    if (loading) {
        return <div>Loading...</div>;
    }

    if (!competitorData) {
        return <div>No competitor data found.</div>;
    }

    return ( // 4.0 • 517 Ratings
        <div className='ml-4 mt-4'>
            <div className="px-4 sm:px-0">
                <h3 className="text-base font-semibold leading-7 text-gray-900">{competitorData.title !== undefined && competitorData.title}</h3>
                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">{competitorData.id !== undefined && competitorData.id} / {competitorData.appId !== undefined && competitorData.appId}</p>
            </div>
            <div className="mt-2">
                <dl className="grid grid-cols-1 sm:grid-cols-2">
                    <div className="border-t border-gray-100 px-4 py-2 sm:col-span-2 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Release notes</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                            {competitorData.releaseNotes !== undefined && <NewlinesToBr text={competitorData.releaseNotes} />}
                        </dd>
                    </div>
                    <div className="border-t border-gray-100 px-4 py-2 sm:col-span-2 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">About</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                            {competitorData.description !== undefined && <NewlinesToBr text={competitorData.description} />}
                        </dd>
                    </div>
                    <div className="border-t border-gray-100 px-4 py-2 sm:col-span-1 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Developer</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                            {competitorData.developer !== undefined && competitorData.developer} &nbsp;
                            <span className='underline'>{competitorData.developerWebsite !== undefined && competitorData.developerWebsite}</span>
                        </dd>
                    </div>
                    <div className="border-t border-gray-100 px-4 py-2 sm:col-span-1 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Rating score</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                            All: {competitorData.score !== undefined && competitorData.score.toFixed(2)} from &nbsp;
                            {competitorData.reviews !== undefined && `${competitorData.reviews} reviews`}
                            <br />
                            Current: {competitorData.currentVersionScore !== undefined && competitorData.currentVersionScore.toFixed(2)} from &nbsp;
                            {competitorData.currentVersionReviews !== undefined && `${competitorData.currentVersionReviews} reviews`}
                        </dd>
                    </div>
                    <div className="border-t border-gray-100 px-4 py-2 sm:col-span-1 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Launched {relativeDateString(competitorData.released)}</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{strToDate(competitorData.released).toDateString()}</dd>
                    </div>
                    <div className="border-t border-gray-100 px-4 py-2 sm:col-span-1 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Updated {relativeDateString(competitorData.updated)}</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{strToDate(competitorData.updated).toDateString()}</dd>
                    </div>
                    <div className="border-t border-gray-100 px-4 py-2 sm:col-span-1 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Current version</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                            {competitorData.version !== undefined && competitorData.version} &nbsp; / &nbsp;&nbsp;
                            {competitorData.size !== undefined && formatBytes(competitorData.size)}
                        </dd>
                    </div>
                    <div className="border-t border-gray-100 px-4 py-2 sm:col-span-1 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Requires iOS version</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{competitorData.requiredOsVersion !== undefined && competitorData.requiredOsVersion} +</dd>
                    </div>
                    <div className="border-t border-gray-100 px-4 py-2 sm:col-span-1 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Content rating</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{competitorData.contentRating !== undefined && competitorData.contentRating}</dd>
                    </div>
                    <div className="border-t border-gray-100 px-4 py-2 sm:col-span-1 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Genres</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                            {competitorData.genres.map((element, index) => (
                                <span key={index}>
                                    {element}
                                    {index < competitorData.genres.length - 1 && ', '}
                                </span>
                            ))}
                        </dd>
                    </div>
                    <div className="border-t border-gray-100 px-4 py-2 sm:col-span-2 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Languages</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                            {competitorData.languages.map((element, index) => (
                                <span key={index}>
                                    {element}
                                    {index < competitorData.languages.length - 1 && ', '}
                                </span>
                            ))}
                        </dd>
                    </div>
                    <div className="border-t border-gray-100 px-4 py-2 sm:col-span-1 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">App Store app listing page</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                            <a href={competitorData.url} target="_new" className="font-medium text-indigo-600 hover:text-indigo-500">
                                Web site
                            </a>
                        </dd>
                    </div>
                    <div className="border-t border-gray-100 px-4 py-2 sm:col-span-1 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">App Store developer listing page</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                            <a href={competitorData.developerUrl} className="font-medium text-indigo-600 hover:text-indigo-500">
                                Web site
                            </a>
                        </dd>
                    </div>
                    <div className="border-t border-gray-100 px-4 py-2 sm:col-span-2 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Previous versions (fixme)</dt>
                        <dd className="mt-2 text-sm text-gray-900">
                            <ul className="divide-y divide-gray-100 rounded-md border border-gray-200">
                                <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                    <div className="flex w-0 flex-1 items-center">
                                        Fixme
                                    </div>
                                    <div className="ml-4 flex-shrink-0">
                                        <a href={competitorData.url} target="_new" className="font-medium text-indigo-600 hover:text-indigo-500">
                                            Details
                                        </a>
                                    </div>
                                </li>
                                <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                    <div className="flex w-0 flex-1 items-center">
                                        Fixme
                                    </div>
                                    <div className="ml-4 flex-shrink-0">
                                        <a href={competitorData.developerUrl} target="_new" className="font-medium text-indigo-600 hover:text-indigo-500">
                                            Details
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    )
}

/* {      
    "icon": "https://is1-ssl.mzstatic.com/image/thumb/Purple124/v4/27/0e/06/270e065d-4bf7-cda1-6cc4-f02dd192f7c0/AppIcon-1x_U007emarketing-0-8-0-85-220.png/512x512bb.jpg",
    "ipadScreenshots": [
        "https://is1-ssl.mzstatic.com/image/thumb/Purple113/v4/d0/e9/be/d0e9be2b-306f-1657-07df-388d5cba6768/pr_source.png/576x768bb.png",
        "https://is1-ssl.mzstatic.com/image/thumb/Purple123/v4/4f/fc/e3/4ffce3f3-c6ba-6c15-ab8b-b39274bc4c3e/pr_source.png/576x768bb.png",
        "https://is1-ssl.mzstatic.com/image/thumb/Purple123/v4/1f/71/34/1f713459-5f70-0032-8b0c-97a82f2b8240/pr_source.png/576x768bb.png",
        "https://is1-ssl.mzstatic.com/image/thumb/Purple113/v4/9d/8a/62/9d8a62a6-66a8-a926-1b01-f9cfb379e197/pr_source.png/576x768bb.png",
        "https://is1-ssl.mzstatic.com/image/thumb/Purple123/v4/43/4b/ad/434bad14-c9fa-6073-cd98-68b5ed97f9dc/pr_source.png/576x768bb.png",
        "https://is1-ssl.mzstatic.com/image/thumb/Purple123/v4/34/12/6e/34126edb-0f37-1db0-80a6-c3effa36f76e/pr_source.png/576x768bb.png",
        "https://is1-ssl.mzstatic.com/image/thumb/Purple113/v4/21/4e/e3/214ee334-51e8-6086-1042-0985cf4b30bd/pr_source.png/576x768bb.png",
        "https://is1-ssl.mzstatic.com/image/thumb/Purple113/v4/a5/ed/f4/a5edf4c0-1d39-b600-c9e4-6b75d7706b5b/pr_source.png/576x768bb.png",
        "https://is1-ssl.mzstatic.com/image/thumb/Purple123/v4/c8/66/b2/c866b280-b9fd-bdb0-8c58-823146b4f683/pr_source.png/576x768bb.png"
    ],
    "screenshots": [
        "https://is1-ssl.mzstatic.com/image/thumb/Purple113/v4/91/cb/74/91cb7449-113a-0dbc-aed4-343545b331b9/pr_source.png/392x696bb.png",
        "https://is1-ssl.mzstatic.com/image/thumb/Purple123/v4/f1/62/23/f16223e3-bd91-16a0-f069-9402775827f8/pr_source.png/392x696bb.png",
        "https://is1-ssl.mzstatic.com/image/thumb/Purple123/v4/7c/a3/6d/7ca36d32-2c38-e78b-ce91-ee8f11947700/pr_source.png/392x696bb.png",
        "https://is1-ssl.mzstatic.com/image/thumb/Purple123/v4/21/fc/76/21fc7673-c919-2fde-cb5d-0dd61f14bd2e/pr_source.png/392x696bb.png",
        "https://is1-ssl.mzstatic.com/image/thumb/Purple123/v4/d3/9c/fe/d39cfeb2-9ba5-127b-4d9d-27fc8bbea7f7/pr_source.png/392x696bb.png",
        "https://is1-ssl.mzstatic.com/image/thumb/Purple123/v4/03/3a/de/033ade77-dbe1-7588-3901-b67cb5e17d06/pr_source.png/392x696bb.png",
        "https://is1-ssl.mzstatic.com/image/thumb/Purple113/v4/7a/78/22/7a78227b-8330-348b-b574-73ff29305e8a/pr_source.png/392x696bb.png",
        "https://is1-ssl.mzstatic.com/image/thumb/Purple113/v4/11/41/16/114116a9-bc84-409a-0c95-7649f9371360/pr_source.png/392x696bb.png",
        "https://is1-ssl.mzstatic.com/image/thumb/Purple113/v4/13/37/9e/13379e01-7b97-36db-bbf3-e9bdac25788f/pr_source.png/392x696bb.png"
    ],
    "appletvScreenshots": []   
} */