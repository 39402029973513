import { useFirestore, useFirestoreCollection } from 'reactfire';
import { collection, query, orderBy } from 'firebase/firestore';
import { formatRelativeDate, formatTimestampRelative, formatNotificationType, formatSubtype } from './Helpers';

export default function CommerceItemList({ sku, itemId }) {
    // FIRESTORE
    const firestore = useFirestore();
    const eventsCollection = collection(firestore, `Products/${sku}/Commerce/${itemId}/Events`);
    const activeQuery = query(eventsCollection, orderBy('created', 'desc'));
    const { status, data: events } = useFirestoreCollection(activeQuery);

    if (status === 'loading') {
        return 'Loading...';
    }

    return (
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <div className="flow-root">
                <ul className="-mb-8">
                    {events.docs.map((event, eventIndex) => (
                        <li key={eventIndex}>
                            <div className="relative pb-8">
                                {eventIndex !== events.docs.length - 1 ? (
                                    <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                                ) : null}
                                <div className="relative flex space-x-3">
                                    <div>
                                        <span className='h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'>
                                            <img className="" src={`/images/ios.png`} alt="" />
                                        </span>
                                    </div>
                                    <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                        <div className="text-right text-sm whitespace-nowrap text-gray-500">
                                            {formatRelativeDate(event.data().created.toDate())}
                                        </div>
                                        <div>
                                            <p className="text-sm text-gray-500">
                                                <span className="font-medium text-gray-900">
                                                    {/* Badge a */}
                                                    <span className="mx-1 inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-indigo-100 text-indigo-800">
                                                        <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-indigo-400" fill="currentColor" viewBox="0 0 8 8">
                                                            <circle cx={4} cy={4} r={3} />
                                                        </svg>
                                                        {formatNotificationType(event.data().notificationType)}
                                                    </span>

                                                    {/* Badge b */}
                                                    {(event.data().subtype !== undefined) &&
                                                        <span className="mx-1 inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-purple-100 text-purple-800">
                                                            {formatSubtype(event.data().subtype)}
                                                        </span>}
                                                </span>
                                            </p>
                                        </div>
                                        <div className="text-right text-sm whitespace-nowrap text-gray-500">
                                            {(event.data().transaction.expiresDate !== undefined) && `Expiry ${formatTimestampRelative(event.data().transaction.expiresDate)}`}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}
