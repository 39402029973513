import React, { useState, useEffect } from 'react';
import usePaginatedReviews from './usePaginatedReviews';
import { relativeDateString } from './FormatHelpers';
import { EyeSlashIcon } from '@heroicons/react/20/solid'
import { EmptyState } from "./Helpers";
import Flag from './Flag.js';
import { collection, doc, updateDoc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';


export default function CompetitorReviews({ verbiaSku, theirSku }) {
    const [include, setInclude] = useState(false); // drives startAt/startAfter inside usePaginatedReviews()
    const [firstItemStack, setFirstItemStack] = useState([]); // maintain each page's first item on a stack as we go fowards, to allow going back easily
    const [referenceItem, setReferenceItem] = useState(null); // going forwards we start after the current last visible item ; going backwards we start at the previous page's first item on the stack
    const [previousDisabled, setPreviousDisabled] = useState(true);
    const [nextDisabled, setNextDisabled] = useState(true);
    const resultsPerPage = 20;

    // HOOKS
    const firestore = useFirestore();
    const reviews = usePaginatedReviews(referenceItem, include, resultsPerPage, verbiaSku, theirSku);
    const currentLastVisibleItem = (reviews !== undefined) ? reviews.docs[reviews.size - 1] : null;

    // PAGINATION HELPERS

    const nextPage = () => {
        setFirstItemStack([...firstItemStack, reviews.docs[0]]);
        setInclude(false);
        setReferenceItem(currentLastVisibleItem);
    }

    const previousPage = () => {
        var tempArray = [...firstItemStack]; // copy by value!
        tempArray.pop();
        setFirstItemStack(tempArray);

        setInclude(true);
        setReferenceItem(firstItemStack[firstItemStack.length - 1]);
    }

    const firstPage = () => {
        if (!firstItemStack.length > 1) {
            return
        }

        // go to first
        setInclude(true);
        setReferenceItem(firstItemStack[0]);

        // clear array
        var tempArray = [];
        setFirstItemStack(tempArray);
    }

    useEffect(() => {
        // no back button if stack has nothing to go back to
        if (firstItemStack.length > 0) {
            setPreviousDisabled(false);
        } else {
            setPreviousDisabled(true);
        }

        // no forward button if number of items % rpp > 0
        if (reviews !== undefined && reviews.size % resultsPerPage === 0) {
            setNextDisabled(false);
        } else if (reviews !== undefined) {
            setNextDisabled(true);
        }
    }, [firstItemStack, reviews]);

    // OTHER

    const hideReview = async (docId) => {
        const reviewsCollection = collection(firestore, `Products/${verbiaSku}/Competitors/${theirSku}/Reviews`);
        const reviewDocRef = doc(reviewsCollection, docId);

        await updateDoc(reviewDocRef, { vsHidden: true });
    };

    return (
        <>
            {reviews.size === 0 && <EmptyState message="No reviews found." />}

            {/* Filter: <p>Rating (all,etc) | Store (us, etc) | Flagged (all,*)</p> */}

            {reviews.size > 0 && <table className="mt-6 min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                    <tr key='hdr'>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Store</th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Score</th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Title</th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User name</th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">&nbsp;</th>
                    </tr>
                </thead>
                {reviews.docs.map((review, itemIdx) => (
                    <tbody key={`tb_${itemIdx}`} className="group">
                        <tr key={`A_${itemIdx}`} className={itemIdx % 2 === 0 ? 'bg-white group-hover:bg-gray-200' : 'bg-gray-50 group-hover:bg-gray-200'}>
                            <td className="px-6 pt-2 whitespace-nowrap text-sm text-gray-900">
                                {review.data().vsStorefront && <Flag iso={review.data().vsStorefront} />}
                            </td>
                            <td className="whitespace-nowrap flex justify-center">
                                {review.data().score !== undefined && review.data().score === 1 && <div className="text-sm font-bold w-5 h-5 bg-red-500 rounded-full flex items-center justify-center text-white">{review.data().score}</div>}
                                {review.data().score !== undefined && review.data().score === 2 && <div className="text-sm font-bold w-5 h-5 bg-orange-400 rounded-full flex items-center justify-center text-white">{review.data().score}</div>}
                                {review.data().score !== undefined && review.data().score === 3 && <div className="text-sm font-bold w-5 h-5 bg-amber-400 rounded-full flex items-center justify-center text-white">{review.data().score}</div>}
                                {review.data().score !== undefined && review.data().score === 4 && <div className="text-sm font-bold w-5 h-5 bg-lime-500 rounded-full flex items-center justify-center text-white">{review.data().score}</div>}
                                {review.data().score !== undefined && review.data().score === 5 && <div className="text-sm font-bold w-5 h-5 bg-lime-500 rounded-full flex items-center justify-center text-white">{review.data().score}</div>}
                            </td>
                            <td className="px-6 pt-2 whitespace-nowrap text-sm font-semibold text-gray-900">
                                {review.data().title && review.data().title}
                            </td>
                            <td className="px-6 pt-2 whitespace-nowrap text-sm text-gray-900">
                                {review.id && review.id} <br/>
                                {review.data().userName && review.data().userName}
                            </td>
                            <td className="px-6 pt-2 whitespace-nowrap text-sm text-gray-900">
                                {review.data().updated && relativeDateString(review.data().updated)}
                            </td>
                            <td className="px-6 pt-2 whitespace-nowrap text-sm text-gray-900">
                                <button className="focus:outline-none" onClick={() => { hideReview(review.id) }}>
                                    <EyeSlashIcon className="text-gray-500 block h-5 w-5" />
                                </button>
                            </td>
                        </tr><tr className={itemIdx % 2 === 0 ? 'bg-white group-hover:bg-gray-200' : 'bg-gray-50 group-hover:bg-gray-200'}>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td colSpan="4" className="px-6 pt-2 text-sm text-gray-900">
                                {review.data().text && review.data().text}
                            </td>
                        </tr>
                    </tbody>
                ))}
            </table>}

            {/* pagination area */}
            {reviews.docs.length > 0 &&
                <nav
                    className="mt-6 px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6" aria-label="Pagination">
                    <div className="hidden sm:block">
                        <p className="text-sm text-gray-700">
                            Showing <span className="font-medium">{(firstItemStack.length * resultsPerPage) + 1}</span> to <span className="font-medium">{firstItemStack.length === 0 ? reviews.docs.length : (firstItemStack.length * resultsPerPage) + resultsPerPage}</span>
                        </p>
                    </div>
                    <div className="flex-1 flex justify-between sm:justify-end">
                        {firstItemStack.length > 1 &&
                            <button onClick={firstPage} disabled={previousDisabled} className="disabled:opacity-50 mr-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">First</button>
                        }

                        <button onClick={previousPage} disabled={previousDisabled} className="disabled:opacity-50 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">Previous</button>
                        <button onClick={nextPage} disabled={nextDisabled} className="disabled:opacity-50 ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">Next</button>
                    </div>
                </nav>
            }
        </>
    )
}