
// DOMAIN HELPERS

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export function formatBundleId(bundleId) {
    switch (bundleId) {
        case "com.verbia.transmission":
            return "Transmission Radio";
        case "com.verbia.mightymarket":
                return "Mighty Market";
        case "com.verbia.mightyreader":
            return "ePub Pro";        
        case "com.verbia.pdflibrary":
                return "PDF Library";        
            default:
            return bundleId;
    }
}

// DISPLAY MAPPING

export function formatNotificationType(t) {
    switch (t) {
        case "CONSUMPTION_REQUEST":
            return "Consumption request";
        case "DID_CHANGE_RENEWAL_PREF":
            return "Changed renewal pref";
        case "DID_CHANGE_RENEWAL_STATUS":
            return "Changed renewal status";
        case "DID_FAIL_TO_RENEW":
            return "Failed to renew";
        case "DID_RENEW":
            return "Renewed";
        case "EXPIRED":
            return "Expired";
        case "GRACE_PERIOD_EXPIRED":
            return "Grace period expired";
        case "OFFER_REDEEMED":
            return "Offer redeemed";
        case "PRICE_INCREASE":
            return "Price increase";
        case "REFUND":
            return "Refund";
        case "REFUND_DECLINED":
            return "Refund declined";
        case "RENEWAL_EXTENDED":
            return "RenewalExtended";
        case "REVOKE":
            return "Revoke";
        case "SUBSCRIBED":
            return "Subscribed";
        default:
            return t;
    }
}

export function formatSubtype(t) {
    switch (t) {
        case "INITIAL_BUY":
            return "Initial buy";
        case "RESUBSCRIBE":
            return "Resubscribe";
        case "DOWNGRADE":
            return "Downgrade";
        case "UPGRADE":
            return "Upgrade";
        case "AUTO_RENEW_ENABLED":
            return "Auto renew enabled";
        case "AUTO_RENEW_DISABLED":
            return "Auto renew disabled";
        case "VOLUNTARY":
            return "Voluntary";
        case "BILLING_RETRY":
            return "Billing retry";
        case "PRICE_INCREASE":
            return "Price increase";
        case "GRACE_PERIOD":
            return "Grace period";
        case "BILLING_RECOVERY":
            return "Billing recovery";
        case "PENDING":
            return "Pending";
        case "ACCEPTED":
            return "Accepted";
        default:
            return t;
    }
}

// STRING HELPERS

export function titleCase(str) {
    str = str.toLowerCase().split(' ');
    for (var i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(' ');
}

// DATE HELPERS

export function formatTimestamp(t) {
    const date = new Date(t);
    return formatDate(date);
}

export function formatTimestampRelative(t) {
    const date = new Date(t);
    return formatRelativeDate(date);
}

export function formatDate(d) {
    var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var hours = d.getHours();
    var minutes = d.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return d.getDate() + " " + monthNames[d.getMonth()] + " " + d.getFullYear() + " at " + strTime;
}

export function formatRelativeDate(date) {
    // let dayOfMonth = date.getDate();
    // let month = date.getMonth() + 1;
    // let year = date.getFullYear();
    let hour = date.getHours();
    let minutes = date.getMinutes();
    let diffMs = new Date() - date;
    let diffSec = Math.round(diffMs / 1000);
    let diffMin = diffSec / 60;
    let diffHour = diffMin / 60;

    // formatting
    // year = year.toString().slice(-2);
    // month = month < 10 ? '0' + month : month;
    // dayOfMonth = dayOfMonth < 10 ? '0' + dayOfMonth : dayOfMonth;
    hour = hour < 10 ? '0' + hour : hour;
    minutes = minutes < 10 ? '0' + minutes : minutes;

    var ampm = hour >= 12 ? 'PM' : 'AM';
    const strTime = `${hour}:${minutes} ${ampm}`;

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    if (yesterday.toDateString() === date.toDateString()) {
        return "Yesterday at " + strTime;
    }

    if (diffSec < 1) {
        return 'Right now';
    } else if (diffMin < 1) {
        return `${diffSec} seconds ago`
    } else if (diffHour < 1) {
        return `${Math.floor(diffMin)} minutes ago`
    } else if (diffHour < 24) {
        return `${Math.floor(diffHour)} hours ago at ` + strTime;
    } else {
        return formatDate(date)
    }
}

export function EmptyState({ message, border = true }) {
    const displayMessage = (message) ? message : "Empty state"
    return (
        <div className="">
            <div className={classNames('mx-auto my-6 w-4/5 relative block rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500', border === true ? 'border-2 border-gray-300 border-dashed' : '')} >
                <img className="mx-auto h-24" src="/images/empty.svg" alt="Workflow" />
                <span className="mt-2 block text-sm font-medium text-gray-900">{displayMessage}</span>
            </div>
        </div>
    )
}