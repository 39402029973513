import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import useCompetitors from './useCompetitors';
import { EmptyState } from "./Helpers";

// eslint-disable-next-line
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function CompetitorsTabMain({ sku }) {
    const competitors = useCompetitors(sku);
    const routerLocation = useLocation();

    if (!competitors) {
        return <>Loading</>;
    }

    return (
        <div className="-mx-4 mt-2 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg w-full">
            {competitors.docs.length === 0 && <EmptyState message='No competitors loaded' border={false} />}

            {competitors.docs.length > 0 && (
                <div className="grid grid-cols-6 gap-4">
                    {competitors.docs.map((competitor, index) => (
                        <div key={index} className="flex flex-col items-center p-4">
                            <Link to={competitor.id}>
                                <img className="h-20 w-20 rounded-xl" src={competitor.data().icon} alt="" />
                            </Link>
                            <div className="mt-2 text-center">
                                <div className="text-xs text-gray-900">{competitor.data().title}</div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}