import { Link } from 'react-router-dom';
import skus from './Constants.js';

export default function SkuChooser() {
    return (
        <div className="bg-gray-900">
            <div className="mx-auto max-w-7xl py-8 px-6 lg:px-8 lg:py-8">
                <div className="space-y-12">
                    <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
                        <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Meet our apps</h2>
                        {/*<p className="text-xl text-gray-300">Ornare sagittis, suspendisse in hendrerit quis. Sed dui aliquet lectus sit pretium egestas vel mattis neque.</p>*/}
                    </div>
                    <ul className="space-y-4 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:grid-cols-3 lg:gap-8">
                        {skus.map((sku) => (
                            <li key={sku.name} className="rounded-lg bg-gray-800 py-10 px-6 text-center xl:px-10 xl:text-left">
                                <div className="space-y-6 xl:space-y-10">
                                    <Link to={`sku/${sku.sku}`}>
                                        <img className="mx-auto h-40 w-40 xl:h-56 xl:w-56" src={sku.imageUrl} alt="" />
                                    </Link>
                                    <div className="space-y-2 xl:flex xl:items-center xl:justify-between">
                                        <div className="space-y-1 text-lg font-medium leading-6">
                                            <h3 className="text-white">{sku.name}</h3>
                                            <p className="text-gray-400">{sku.sku}</p>
                                        </div>
                                        {/* right align content here */}
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}