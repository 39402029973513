import Transmission from "./transmission";

export default function LandingMain() {
    return (
        <div className="min-h-screen bg-[#F8F8F8] bg-no-repeat bg-right-top" style={{ backgroundImage: 'url("images/bg.png")', backgroundSize: '50%' }}>

            <main className="mb-auto flex-grow">
                <div className="">
                    <div className="shrink">
                        <img aria-roledescription="logo" aria-label="Verbia Systems logo" src="images/logo_home.svg" className="pl-4 sm:pl-14 pt-20" alt="" />
                    </div>
                </div>

                <h1 className="mt-10 sm:mt-10 ml-6 sm:ml-14 text-4xl font-bold tracking-tight text-white lg:mt-20 md:text-5xl xl:text-6xl text-black">
                    <p className="block leading-[1.1]">Inclusive and<br />
                        empowering<br />
                        apps</p>
                </h1>

                <p className="w-1/2 mt-6 ml-6 sm:ml-14 text-base text-gray-500 sm:text-xl lg:text-lg xl:text-xl">
                    Verbia Systems develops elegant apps and utilities to improve your daily life.
                </p>

                <Transmission />
            </main>           
        </div>
    );
}