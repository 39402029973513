import { Link } from 'react-router-dom';

const navigation = {
    main: [
        { name: 'Privacy Policy', href: '/privacy' },
        { name: 'Terms of Use', href: '/terms' },
    ],
}

export default function Footer() {
    return (
        <footer className="bg-[#1F2937] sticky top-[100vh]">
            <div className="mx-auto max-w-7xl overflow-hidden py-12 px-4 sm:px-6 lg:px-8">
                <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
                    {navigation.main.map((item) => (
                        <div key={item.name} className="px-5 py-2">
                            <Link to={item.href} className="text-base text-gray-400 hover:text-gray-300">
                                {item.name}
                            </Link>
                        </div>
                    ))}
                </nav>
                <p className="mt-8 text-center text-base text-gray-500">&copy; 2022 Verbia Systems Pty Ltd. All rights reserved.</p>
            </div>
        </footer>
    )
}