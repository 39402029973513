import { formatRelativeDate } from './Helpers';
import { CurrencyDollarIcon } from '@heroicons/react/24/solid'
import { useBillingCheck } from './useBillingCheck';

export default function FeedbackItem({ item, handler }) {
    // HOOKS
    const isPaying = useBillingCheck(item.sku, item.supportid ? item.supportid : "Not defined");

    const regionNames = new Intl.DisplayNames(
        ['en'], { type: 'region' }
    );

    const languageNames = new Intl.DisplayNames(['en'], {
        type: 'language'
    });

    const goBack = () => {
        handler(undefined);
    }

    return (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
                <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                    <div className="ml-4 mt-2">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Feedback item
                        </h3>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">Received {formatRelativeDate(item.created.toDate())}</p>
                    </div>

                    {isPaying && <CurrencyDollarIcon className="-ml-0.5 mr-1.5 h-12 w-12 text-green-400" fill="currentColor" />}
                    
                    <div className="ml-4 mt-2 flex-shrink-0">
                        <button type="button" onClick={() => { goBack() }} className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Back</button>
                    </div>
                </div>

            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Name</dt>
                        <dd className="mt-1 text-sm text-gray-900">{item.username}</dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Email address</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            {(item.email) && item.email}
                            {!(item.email) && "—"}
                        </dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">SKU</dt>
                        <dd className="mt-1 text-sm text-gray-900">{item.sku}</dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">App Version</dt>
                        <dd className="mt-1 text-sm text-gray-900">{item.appversion}</dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Device</dt>
                        <dd className="mt-1 text-sm text-gray-900">{item.model}</dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">OS Version</dt>
                        <dd className="mt-1 text-sm text-gray-900">{item.osversion}</dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Language</dt>
                        <dd className="mt-1 text-sm text-gray-900">{languageNames.of(item.language)}</dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Region</dt>
                        <dd className="mt-1 text-sm text-gray-900">{regionNames.of(item.region)}</dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Tier</dt>
                        <dd className="mt-1 text-sm text-gray-900">{item.tier}</dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Support ID</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            {(item.supportid !== undefined) && item.supportid}
                            {(item.supportid === undefined) && "—"}
                        </dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Platform</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            {(item.platform !== undefined) && item.platform}
                            {(item.platform === undefined) && "—"}
                        </dd>
                    </div>
                    <div className="sm:col-span-2">
                        <dt className="text-sm font-medium text-gray-500">Comments</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            {item.comments}
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    )
}
