import React, { useState } from 'react';
import FeedbackList from "./FeedbackList";
import FeedbackItem from "./FeedbackItem";

export default function FeedbackMain( {sku} ) {
    // STATE
    const [selectedItem, setSelectedItem] = useState(undefined);

    // LIFTING STATE
    const changeSelectedItem = (item) => {
        setSelectedItem(item);
    }

    return (
        <>
            {selectedItem === undefined && <FeedbackList handler={changeSelectedItem} sku={sku} />}
            {selectedItem !== undefined && <FeedbackItem item={selectedItem} handler={changeSelectedItem} />}
        </>
    )
}