const strToDate = (input) => {
    return new Date(input);
}

const relativeDateString = (input) => {
    const d = strToDate(input);
    return relativeDate(d);
}

const relativeDate = (date) => {
    let difference = (new Date() - date) / 1000; // the difference in milliseconds
    let output = ``;

    if (difference < 60) {
        // Less than a minute has passed:
        output = `${difference} seconds ago`;
    } else if (difference < 3600) {
        const n = Math.floor(difference / 60);
        output = (n === 1) ? `${n} minute ago` : `${n} minutes ago`;
    } else if (difference < 86400) {
        const n = Math.floor(difference / 3600);
        output = (n === 1) ? `${n} hour ago` : `${n} hours ago`;
    } else if (difference < 2620800) {
        const n = Math.floor(difference / 86400);
        output = (n === 1) ? `${n} day ago` : `${n} days ago`;
    } else if (difference < 31449600) {
        const n = Math.floor(difference / 2620800);
        output = (n === 1) ? `${n} month ago` : `${n} months ago`;
    } else {
        const n = Math.floor(difference / 31449600);
        output = (n === 1) ? `${n} year ago` : `${n} years ago`;
    }

    return output;
}

const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

function NewlinesToBr({ text }) {
    const convertedText = text.replace(/\n{2,}/g, '\n').replace(/\n/g, '<br>');
    return <div dangerouslySetInnerHTML={{ __html: convertedText }} />;
}

export { strToDate, relativeDateString, relativeDate, formatBytes, NewlinesToBr }