import { ChevronRightIcon, EnvelopeIcon } from '@heroicons/react/24/solid'
import { useFirestore, useFirestoreCollection } from 'reactfire';
import { collection, query, orderBy } from 'firebase/firestore';
import { formatRelativeDate } from './Helpers';

export default function FeedbackList( { sku, handler } ) {
    // FIRESTORE
    const firestore = useFirestore();
    const feedbackCollection = collection(firestore, `Products/${sku}/Feedback`);
    const activeQuery = query(feedbackCollection, orderBy('created', 'desc'));
    const { status, data: feedback } = useFirestoreCollection(activeQuery);

    if (status === 'loading') {
        return 'Loading...';
    }

    const selectItem = (e, itemId) => {
        e.preventDefault();
        handler(itemId);
    }

    return (
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
            {feedback.size > 0 && <ul className="divide-y divide-gray-200">
                {feedback.docs.map((item, itemIndex) => (
                    <li key={itemIndex}>
                        <a href="#1" onClick={(e) => selectItem(e, item.data())} className="block hover:bg-gray-50">
                            <div className="flex items-center px-4 py-4 sm:px-6">
                                <div className="min-w-0 flex-1 flex items-center">
                                    <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                        <div>
                                            <p className="text-sm font-medium text-black-600 truncate">
                                                {item.data().username}
                                            </p>
                                            <p className="mt-2 flex items-center text-sm text-gray-500">
                                                <EnvelopeIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                {(item.data().email) && <span className="truncate">{item.data().email}</span>}
                                                {!(item.data().email) && <span className="truncate">—</span>}
                                            </p>
                                        </div>
                                        <div className="hidden md:block">
                                            <div>
                                                <p className="mt-2 flex items-center text-sm text-gray-900">
                                                    Received {formatRelativeDate(item.data().created.toDate())}
                                                    <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">New</span>
                                                </p>
                                                <p className="mt-2 flex items-center text-sm text-gray-500">
                                                    {item.data().platform === 'iOS' && <img className="flex-shrink-0 mr-1.5 h-5 w-5" src={`/images/ios.png`} alt="" />}
                                                    {item.data().platform === 'Android' && <img className="flex-shrink-0 mr-1.5 h-5 w-5" src={`/images/android.png`} alt="" />}
                                                    {item.data().sku}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div>
                            </div>
                        </a>
                    </li>
                ))}
            </ul>}
        </div >
    )
}