import React from 'react';
import { EmptyState } from "./Helpers";

export default function CompetitorRatings() {

    return (
        <>
            <EmptyState message="No ratings found." />
        </>
    )
}