import { Disclosure } from '@headlessui/react'
import { ChevronRightIcon, } from '@heroicons/react/20/solid'
import { CreditCardIcon, UsersIcon, ChatBubbleLeftEllipsisIcon, ChartBarIcon, PresentationChartLineIcon } from '@heroicons/react/24/outline'
import { Routes, Route, Link, useParams } from "react-router-dom"
import CompetitorsTabMain from './CompetitorsTabMain'
import CompetitorDetailsMain from './CompetitorDetailsMain'
import FeedbackMain from './FeedbackMain';
import CommerceMain from './CommerceMain';
import skus from './Constants';
import { EmptyState } from "./Helpers";

const navigation = [
    { name: 'Commerce', href: 'commerce', icon: CreditCardIcon, current: false, enabled: true },
    { name: 'Competitors', href: 'competitors', icon: UsersIcon, current: true, enabled: true },
    { name: 'App Feedback', href: 'feedback', icon: ChatBubbleLeftEllipsisIcon, current: false, enabled: true },
    { name: 'App Store Connect', href: 'asc', icon: PresentationChartLineIcon, current: false, enabled: false },
    { name: 'TelemetryDeck', href: 'telemetry', icon: ChartBarIcon, current: false, enabled: false },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function SkuChooser() {
    const { skuId } = useParams();
    const skuDetails = skus.find((item) => item.sku === skuId);

    return (
        <div className="flex">
            {/* Left column */}
            <div className="w-[180px]">
                <div className="flex h-16 items-center mb-6">
                    <img className="h-[85px] w-auto mx-auto flex-shrink-0 pr-4" src={skuDetails.imageUrl} alt="" />
                </div>
                <nav className="flex flex-1 flex-col">
                    <ul className="flex flex-1 flex-col gap-y-7">
                        <li>
                            <ul className="-mx-2 space-y-1">
                                {navigation.map((item) => (
                                    <li key={item.name}>
                                        {!item.children ? (
                                            <Link to={item.href} className={classNames(item.current ? 'bg-gray-50' : 'hover:bg-gray-50', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-700')}>
                                                <item.icon className="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" />
                                                {item.name}
                                            </Link>
                                        ) : (
                                            <Disclosure as="div">
                                                {({ open }) => (
                                                    <>
                                                        <Disclosure.Button className={classNames(item.current ? 'bg-gray-50' : 'hover:bg-gray-50', 'flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-700')}>
                                                            <item.icon className="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" />
                                                            {item.name}
                                                            <ChevronRightIcon className={classNames(open ? 'rotate-90 text-gray-500' : 'text-gray-400', 'ml-auto h-5 w-5 shrink-0')} aria-hidden="true" />
                                                        </Disclosure.Button>
                                                        <Disclosure.Panel as="ul" className="mt-1 px-2">
                                                            {item.children.map((subItem) => (
                                                                <li key={subItem.name}>
                                                                    {/* 44px */}
                                                                    <Disclosure.Button as="Link" to={subItem.href} className={classNames(subItem.current ? 'bg-gray-50' : 'hover:bg-gray-50', 'block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-gray-700 whitespace-nowrap')}>
                                                                        {subItem.name}
                                                                    </Disclosure.Button>
                                                                </li>
                                                            ))}
                                                        </Disclosure.Panel>
                                                    </>
                                                )}
                                            </Disclosure>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>


            {/* Right column */}
            <Routes> {/* relative to /app/:skuId/ */}
                <Route exact path="/" element={<CommerceMain sku={skuId} />} />
                <Route exact path="/competitors" element={<CompetitorsTabMain sku={skuId} />} />
                <Route path="/competitors/:competitorId/*" element={<CompetitorDetailsMain sku={skuId} />} />
                <Route path="/feedback/*" element={<FeedbackMain sku={skuId} />} />
                <Route path="/commerce/*" element={<CommerceMain sku={skuId} />} />
                <Route path="/asc/*" element={<EmptyState message='App Store Connect not yet configured' border={true} />} />
                <Route path="/telemetry/*" element={<EmptyState message='TelemetryDeck not yet configured' border={true} />} />
            </Routes>
        </div>
    )
}
